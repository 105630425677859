<template>
  <switch-profile-nav />
</template>

<script>
import switchProfileNav from './switchProfileNav.vue'

export default {
  components: {
    switchProfileNav,
  },
}
</script>
