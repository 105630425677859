<template>
  <a-modal
    :visible="visible"
    title="选择登录渠道"
    :closable="true"
    @cancel="closeModal"
  >
    <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="选择登录渠道">
        <ayg-select
          style="width:80%;"
          :data="loginAgentList"
          placeholder="请选择"
          v-decorator="['subject', { initialValue: initialValue.subject, rules: [{ required: true, message: '请选择登录渠道' }] }]"
          :props="{ value: 'value', label: 'label' }"
        />
      </a-form-item>
    </a-form>
    <div slot="footer">
      <a-button type="primary" @click="scrmAgentLogin('')">确定</a-button>
      <a-button @click="closeModal">取消</a-button>
    </div>
  </a-modal>
</template>
<script>
/* 只针对善赚营销平台登录 */
import { mapState, mapMutations } from 'vuex'
import {
  setSelectedChannel,
  getChannels,
  getSelectedChannel,
  setSelectedAgent,
  setChosen,
} from '@/utils/auth'

const initialValueEnum = {
  'console-scrm': {
    subject: getSelectedChannel, // 善赚营销平台sales端的企业id为渠道商id(consloe-scrm平台的用户类型都是agent)
  },
}


export default {
  inject: ['reload'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'scrmSwitchUserLogin' }),
      initialValue: {
        subject: undefined,
      },
    }
  },
  computed: {
    ...mapState(['platformType']),
    userProfiles() {
      const platfumStorageEnum = {
        'console-scrm': getChannels,
      }
      if (!(this.platformType in platfumStorageEnum)) return []
      return platfumStorageEnum[this.platformType].call() || []
    },
    loginAgentList() {
      const tempArr = this.list.length !== 0 ? this.list : this.userProfiles
      return tempArr.map((el) => ({
        ...el,
        label: el.subjectName || el.name,
        value: el.id,
      }))
    },
  },
  watch: {
    visible(nv) {
      if (nv) {
        const subject = Number(initialValueEnum[this.platformType]?.subject()) || undefined
        const industry = this.loginAgentList.find((el) => el.id === subject)
        this.initialValue = industry ? { subject } : { subject: undefined }
      }
    },
  },
  methods: {
    ...mapMutations(['updateState']),
    scrmAgentLogin(val) {
      let subject = {}
      if (!this.visible) {
        subject = val
      } else {
        const subjectFormVal = this.form.getFieldValue('subject')
        if (!subjectFormVal) {
          this.form.validateFields(['subject'])
          return
        }
        subject = this.loginAgentList.find((el) => el.id === subjectFormVal)
      }
      const {
        id, employeeId, subjectId, subjectName,
      } = subject
      this.agentSubjectLogic(id, employeeId, subjectName, subjectId)
      this.agentObjLogic({ agentId: subjectId, agentName: subjectName })
    },
    // 代理商平台选择操作对象后
    agentObjLogic(object) {
      if (!object) return
      const { platformType } = this
      const { agentId, agentName } = object || {}
      const currentChosen = { id: agentId, name: agentName, platformType }
      const headerEnums = {
        id: `x-sec-subject-${platformType.split('-')[1]}-id`,
        name: `x-sec-subject-${platformType.split('-')[1]}-name`,
      }
      setSelectedAgent(agentId, agentName)
      setChosen(currentChosen)
      this.updateState({
        name: 'currentChosen',
        value: currentChosen,
      })
      this.setHeaderConfig({
        [headerEnums.id]: agentId,
        [headerEnums.name]: encodeURI(agentName),
      })
      this.refresh()
    },
    refresh() {
      this.form.resetFields()
      this.$emit('update:visible', false)
      this.reload()
    },
    agentSubjectLogic(id, employeeId, name, subjectId) {
      this.commonCookie({
        id,
        employeeId,
        name,
        subjectId,
      })
      this.updateState({
        name: 'selectedChannel',
        value: id,
      })
      setSelectedChannel(id)
    },
    async checkNullValue(sourceObj) {
      const result = {}
      await Object.keys(sourceObj).forEach((el) => {
        sourceObj[el]
          && sourceObj[el] !== 'null'
          && Object.assign(result, {
            [el]: sourceObj[el],
          })
      })
      return result
    },
    async setHeaderConfig(config) {
      const headerConfig = await this.checkNullValue(
        JSON.parse(localStorage.getItem('headerConfig') || '{}'),
      )
      localStorage.setItem(
        'headerConfig',
        JSON.stringify({
          ...headerConfig,
          ...config,
        }),
      )
    },
    // 各端公有的cookie
    commonCookie({
      id, employeeId, name, subjectId,
    }) {
      const { platformType } = this
      const subjectAffix = platformType.split('-')[1]
      window.localStorage.setItem('userProfiles', id)
      document.cookie = `redash-profile=${id};domain=.aiyuangong.com;path=/`
      document.cookie = `x-sec-profile=${id}; path=/`
      document.cookie = `x-sec-employee=${employeeId}; path=/`
      const headerEnums = {
        id: `x-sec-subject-${subjectAffix}-id`,
        name: `x-sec-subject-${subjectAffix}-name`,
      }
      this.setHeaderConfig({
        'x-sec-profile': id,
        'x-sec-employee': employeeId,
        [headerEnums.id]: subjectId,
        [headerEnums.name]: encodeURI(name),
        platformType,
      })
    },
    closeModal() {
      this.updateState({ name: 'loginLoading', value: false })
      this.form.resetFields()
      this.$emit('update:visible', false)
    },
  },
}
</script>
