<template>
  <a-modal :visible="visible" title="问题反馈" width="800px" @ok="submit" @cancel="closeModal" :zIndex="2021">
    <a-form :form="form" :label-col="{span: 4}" :wrapper-col="{span: 18}">
      <a-form-item v-if="!userInformation.name" label="登录账号">
        <a-input
          v-decorator="['loginName', {
            rules: [{
              required: true,
              message: '请填写登录账号'
            }]
          }]"
          placeholder="请填写用户名" />
      </a-form-item>
      <a-form-item label="问题描述">
        <a-textarea
          v-decorator="['description', {
            rules: [{
              required: true,
              message: '请填写问题描述'
            }]
          }]"
          :autoSize="{ minRows: 2, maxRows: 6 }"
          placeholder="请填写问题描述" />
      </a-form-item>
      <a-form-item label="问题截图">
        <ayg-upload-oss
          v-decorator="['downloadCodes', {
            rules: [{ required: true,  message: '请上传问题截图' }]
          }]"
          buttonText="可以同时上传多张"
          multiple
          @upload-success="uploadSuccess"
          :customFileName="customFileName"
          :imageList="imageList" />
          <span v-if="!downloadCodes.length && submitFlag" style="color: red;">请上传问题截图</span>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { mapState } from 'vuex'
import { v4 } from 'uuid'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      imageList: [],
      customFileName: `用户反馈记录json.${v4()}.txt`,
      downloadCodes: [], // 是个数组，上传一张也是数组
      submitFlag: false,
    }
  },
  computed: {
    ...mapState(['userInformation']),
  },
  watch: {
    visible(val) {
      if (val) {
        this.downloadCodes = []
      }
    },
  },
  methods: {
    closeModal() {
      this.form.resetFields()
      this.submitFlag = false
      this.imageList = []
      this.$emit('update:visible', false)
    },

    async submit() {
      this.submitFlag = true
      this.form.validateFields(['loginName', 'description'], (err, values) => {
        values.downloadCodes = this.downloadCodes
        if (!err) {
          if (!this.downloadCodes.length) return
          this.$emit('submit', values)
          this.closeModal()
        }
      })
    },

    uploadSuccess(file) {
      this.downloadCodes.push(file)
    },
  },
}
</script>
