<template>
  <div class="oepn-nav">
    <a class="ant-dropdown-link" href="javascript:;" @click="openModal">
      {{ userContext.subjectName }}
      <a-icon type="down" />
    </a>
    <component
      :is="currentCompnent"
      :show-modal.sync="showModal"
      :type.sync="formData.subjectType"
      :page.sync="formData.page"
      :page-size.sync="formData.pageSize"
      :industries="companyList"
      :loading="loading"
      :total="total"
      @query="query"
      @change="setUserContext"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import companiesModal from '@/components/companies.vue'
import companies4ClientModal from '@/components/companies4clients.vue'
import { setOpenHeaders, subjectEnums } from '@/utils/auth'
import Cookies from 'js-cookie'

export default {
  components: {
    'companies-modal': companiesModal, // 内部账号查询企业弹窗
    'companies-client-modal': companies4ClientModal, // 客户账号查询企业弹窗
  },
  inject: ['reload'],
  data() {
    return {
      showModal: false, // 内部用户
      showPassword: false,
      formData: {
        page: 1,
        pageSize: 5,
        subjectType: 'company',
      },
      companyList: [],
      total: 0,
      totalApps: 0,
      loading: false,
      profileId: '',
      employeeId: '',
    }
  },
  computed: {
    ...mapState([
      'userInformation',
      'companyInformation',
      'platformType',
      'currentChosen',
      'userContext',
      'innerUser',
      'viewCompanyPermission',
    ]),
    currentCompnent() {
      return !this.innerUser ? 'companies-client-modal' : 'companies-modal'
    },
  },
  watch: {
    // 获取菜单之后更新数据
    userInformation: {
      immediate: true,
      deep: true,
      handler() {
        if (this.userInformation) {
          const platform = this.platformType
          const needInit = platform === 'console-company'
          console.log('update userInformation', this.userContext)
          needInit && this.initData()
        }
      },
    },
  },
  methods: {
    ...mapMutations(['updateState']),
    ...mapActions(['getMenu']),
    openModal() {
      this.showModal = true
      this.formData = {
        page: 1,
        pageSize: 5,
        subjectType: !this.innerUser ? 'app' : this.formData.subjectType,
      }
      this.query(this.formData)
    },
    // 初始化数据
    initData() {
      const { userContextCollectionMap } = this.userInformation.userProfile
      const that = this
      if (!userContextCollectionMap) {
        that.$warning({
          title: '错误',
          content: '您没有登录该系统的权限，请联系管理员！',
          onOk: () => {
            that.onClick({ key: 'logOut' })
          },
        })
      } else if (
        !userContextCollectionMap.company
        || this.userContext.lvl1SubjectId
      ) {
        this.formData.subjectType = 'app'
      }
      this.query()
    },
    async onClick({ key }) {
      if (key === 'logOut') {
        await this.$post('/api/console-dlv/auth/logout')
        this.$store.commit('LOG_OUT')
      } else {
        this.showPassword = true
      }
    },
    // 客户商户列表
    async clientQuery(queryForm) {
      let viewCompanyPermission = true
      Object.assign(queryForm, {
        // 同步分页pageSize，客户手动unshift了一条企业数据
        pageSize: queryForm.pageSize - 1,
      })
      // 查看有无company权限
      const appResult = await this.$post(
        '/api/orgmgt-web/subject-data-permissions/query-user-context',
        {
          ...queryForm,
          subjectType: 'company',
        },
      )
      viewCompanyPermission = appResult.data.total > 0
      const res = await this.$post(
        '/api/orgmgt-web/subject-data-permissions/query-user-context',
        {
          ...queryForm,
          subjectType: 'app',
        },
      )
      const list = res.data.list || []
      const index = this.companyInformation.findIndex(
        (e) => `${e.id}` === `${queryForm.userProfileId}`,
      )
      let count = 0
      const [subject] = list || []
      const { subjectId, subjectName, subjectType } = this.companyInformation[index] || {}
      // 如果返回只有一条，释放
      list.length === 1 && list.pop()
      const industryInfo = viewCompanyPermission
        ? {
          lvl1SubjectId: null,
          lvl1SubjectName: null,
          subjectId,
          subjectName,
          subjectType,
        }
        : subject || {}
      // 第一条塞入企业信息
      list.unshift(industryInfo)
      // 有公司权限的可以查询发票接口，无则不查询发票接口
      this.updateState({
        name: 'viewCompanyPermission',
        value: viewCompanyPermission,
      })
      this.companyList = list
      // 同步分页total数
      const totalPages = Math.ceil(res.data.total / queryForm.pageSize)
      count += res.data.total === 1 ? 0 : totalPages
      this.total = res.data.total + count
    },
    // 内部企业/商户列表
    async innerQuery(queryForm) {
      const res = await this.$post(
        '/api/sysmgr-web/auth/query-user-context',
        queryForm,
      )
      let list = res.data.list || []
      let total = res.data.total || 0
      if (
        queryForm.subjectType === 'company'
        && total === 0
        && !this.userContext
      ) {
        const app = await this.$post(
          '/api/sysmgr-web/auth/query-user-context',
          { ...queryForm, subjectType: 'app' },
        )
        list = app.data.list || []
        total = app.data.total || 0
      }
      this.companyList = list
      this.total = total
    },
    // 获取公司列表
    async query(args = {}) {
      console.log('queryyyyyyyyyyyyyy')
      this.loading = true
      // 以此来判断是否要设置新的userContext
      const initing = Object.keys(args).length === 0
      this.profileId = args.userProfileId || Cookies.get('x-sec-profile')
      this.employeeId = this.companyInformation.find(
        (el) => el.id === Number(this.profileId),
      )?.employeeId
      const userId = localStorage.getItem('userId')
      // 没登录/没写入profile信息前不请求接口
      if (!this.profileId || !userId) return
      this.companyList = []
      try {
        const queryForm = JSON.parse(
          JSON.stringify({
            ...this.formData,
            ...(!this.innerUser
              ? {
                ...args,
                userProfileId: this.profileId,
                userId,
              }
              : args),
          }),
        )
        Object.assign(this.formData, args)
        if (!this.innerUser) {
          await this.clientQuery(queryForm)
        } else {
          await this.innerQuery(queryForm)
        }
        // local没有userContext要重载一遍，解决首次登陆有“岗位服务”权限的账号没出现该菜单的问题
        const [listFirstData] = this.companyList
        // 没有userContext默认取第一条
        const userContext = this.userContext || listFirstData
        initing && this.setUserContext(userContext)
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    // 设置userContext
    setUserContext(row) {
      if (!row) return
      const contextChange = this.userContext.subjectId !== row.subjectId
      if (!contextChange) return
      this.formData.subjectType = row.subjectType
      window.localStorage.setItem('userProfiles', this.profileId)
      Cookies.set('x-sec-profile', this.profileId)
      this.employeeId && Cookies.set('x-sec-employee', this.employeeId)
      const currentChosen = {
        name: row.subjectName,
        id: row.subjectId,
        platformType: 'console-company',
      }
      window.localStorage.setItem('userContext', JSON.stringify(row))
      window.localStorage.setItem(
        'currentChosen',
        JSON.stringify(currentChosen),
      )
      this.updateState({
        name: 'currentChosen',
        value: currentChosen,
      })
      this.updateState({
        name: 'userContext',
        value: row,
      })
      const {
        subjectType,
        subjectId,
        subjectName,
        lvl1SubjectId,
        lvl1SubjectName,
      } = row
      const enums = subjectEnums(subjectType)
      Cookies.set(enums.id, subjectId)
      Cookies.set(enums.name, encodeURI(subjectName))
      if (subjectType === 'app') {
        Cookies.set('x-sec-lvl1subject-app-id', lvl1SubjectId)
        Cookies.set('x-sec-lvl1subject-app-name', encodeURI(lvl1SubjectName))
        Cookies.remove('x-sec-subject-company-id')
        Cookies.remove('x-sec-subject-company-name')
      } else {
        Cookies.remove('x-sec-subject-app-id')
        Cookies.remove('x-sec-subject-app-name')
        Cookies.remove('x-sec-lvl1subject-app-id')
        Cookies.remove('x-sec-lvl1subject-app-name')
      }
      const config = {
        ...(setOpenHeaders(row)[subjectType] || {}),
        platformType: 'console-company',
        'x-sec-profile': this.profileId,
        'x-sec-employee': this.employeeId || '',
      }
      if (!this.employeeId) {
        delete config['x-sec-employee']
      }
      localStorage.setItem('headerConfig', JSON.stringify(config))
      this.showModal = false
      // 更换了userContext要重载页面，重新获取菜单，路由也要返回首页/默认加载页面
      this.reload()
    },
  },
}
</script>

<style scoped>
.open-nav {
  display: inline-block;
}
.ant-dropdown-link {
  color: rgba(0, 0, 0, 0.65);
}
</style>
