<template>
  <div class="upgrade-guide">
    <div class="case">
      <a-carousel :afterChange="getCurrent" arrows>
        <div
          slot="prevArrow"
          class="custom-slick-arrow"
          style="left: 50px; z-index: 1001;"
        >
          <a-icon
            :style="{
              fontSize: '28px',
              color: current > 0 ? '#fff' : 'rgba(255, 255, 255, 0.4)'
            }"
            type="left"
          />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 50px;">
          <a-icon
            :style="{
              fontSize: '28px',
              color: current < picArray.length - 1 ? '#fff' : 'rgba(255, 255, 255, 0.4)'
            }"
            type="right"
          />
        </div>
        <div v-for="(img, i_index) in picArray" :key="i_index" class="pic">
          <img :src="img" alt="" />
          <a-icon
            type="close"
            style="color: #fff; font-size: 24px;"
            @click="close"
          />
        </div>
      </a-carousel>
      <!-- <a-button v-show="current === picArray.length - 1" type="primary" size="large" class="jump-button" @click="close">进入首页</a-button> -->
    </div>
  </div>
</template>
<script>
import P1 from '@/assets/image/guide/P1.png'
import P2 from '@/assets/image/guide/P2.png'
import P3 from '@/assets/image/guide/P3.png'
import P4 from '@/assets/image/guide/P4.png'
import salesGuide from '@/assets/image/salesGuide.png'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      pics: [P1, P2, P3, P4],
      salesPics: [salesGuide],
      current: 0,
    }
  },
  computed: {
    ...mapState(['platformType']),
    picArray() {
      const picsEnum = {
        'console-company': this.pics,
        'console-tenant': this.pics,
        'console-agent': this.salesPics,
      }
      return picsEnum[this.platformType] || []
    },
  },
  methods: {
    getCurrent(current) {
      this.current = current
    },
    close() {
      localStorage.setItem('showGuide', Number(localStorage.getItem('showGuide')) + 1)
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss">
.upgrade-guide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  .case {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .jump-button {
      position: absolute;
      z-index: 1001;
      left: 50%;
      transform: translate(-50%, 850%);
      // margin-top: 74px;
    }
  }
  .ant-carousel {
    text-align: center;
    position: relative;
    width: 1200px;
    // transform: translate(0%, 30%);
    .slick-slider {
      position: inherit;
    }
    .slick-dots {
      margin-top: 24px;
      position: relative;
      li button {
        width: 20px;
      }
      li.slick-active button {
        width: 32px;
      }
    }
    .white {
      color: #fff;
    }
    .grey {
      color: #fff;
      opacity: 0.4;
    }
    .custom-slick-arrow {
      width: 60px;
      height: 60px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      line-height: 80px;
    }
    .pic {
      max-width: 70%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .anticon {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}
</style>
