<template>
  <a-row type="flex">
    <a-col>
      <a-dropdown :trigger="['hover']" v-if="$checkPermissions(permissions, 'performance:/tools')">
        <span>
          <a-icon type="medicine-box" />
          <span style="padding-left: 5px;">工具箱</span>
        </span>
        <a-menu slot="overlay" @click="onClick">
          <a-menu-item key="performance">
            <a href="javascript:;">绩效易 - 绩效分析小助手</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-divider type="vertical" />
    </a-col>
    <a-col>
      <switch-profile-nav />
    </a-col>
  </a-row>
</template>

<script>
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import switchProfileNav from './switchProfileNav.vue'

export default {
  components: { switchProfileNav },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['permissions']),
  },
  created() {
  },
  methods: {
    onClick({ key }) {
      if (key === 'performance') {
        const token = Cookies.get('x-access-token')
        const profile = Cookies.get('x-sec-profile')
        window.open(`${process.env.VUE_APP_KPI_WEB_DOMAIN}?token=${token}&profileId=${profile}`)
      }
    },
  },
}
</script>
