
import {
  getPlatform,
} from '@/utils/auth'
import Cookies from 'js-cookie'
import {
  getStatisticsInfo,
  getApplyInfo,
  getEvaluatRredDot,
  queryTaxReportOrder,
  queryPaymentOrderNum,
  getFlowNum,
  getTaskRedDot,
} from '@/api/login'

// 多收服务费处理流程
const attachServiceChargeFlow = [
  {
    name: 'attachServiceChargeFlow', // name对应菜单路由
    processTaskType: 'attach-service-charge-flow',
    currentTaskNodeId: 'settleReAuth,financeReAuth,delivererMarking,tellerReAuth',
  },
]
// 佣金付款列表
const agentSettleAuditFlow = [
  {
    name: 'agentSettleAuditFlow', // name对应菜单路由
    processTaskType: 'agent-settle-audit-flow',
    currentTaskNodeId: 'offlineSettleOrderApproval,directorApproval,delivererMarking,cashierReview',
  },
]

const applyCompleteTaxFileFlow = [
  {
    currentTaskNodeId: 'createCompleteTaxFileApply,bizCheck,receiptUpdate,financeCreateForm',
    name: 'taxOrderWaitNum',
    processTaskType: 'apply-completeTaxFile-flow',
  },
]

export function getMenusStatistics(commit) {
  if (getPlatform() === 'console-provider') {
    Promise.all([
      getStatisticsInfo(),
      // 获取入驻申请红点
      getApplyInfo(),
      // P端-报税列表待办红点
      queryTaxReportOrder({ queryType: 'waitApproval' }),
      // P端-付款列表待办红点
      queryPaymentOrderNum({ queryType: 'waitApproval' }),
      // 从工单接口获取待办
      getFlowNum({ body: [...attachServiceChargeFlow, ...agentSettleAuditFlow, ...applyCompleteTaxFileFlow] }),
    ].map((p) => p.catch((e) => e))).then((res) => {
      commit('updateState', {
        name: 'menusStatistics',
        value: {
          ...res[0].data,
          companyApplyNum: res[1].code === 200 ? res[1].data.redPointNum : 0,
          waitApprovalNum: res[2].code === 200 ? res[2].data.total : 0,
          paymentOrderNum: res[3].code === 200 ? res[3].data.total : 0,
          ...res[4].data,
        },
      })
    })
  }
  //! getPlatform() === 'console-agent'添加到销售端,合规通tab添加红点
  if (getPlatform() === 'console-agent') {
    const channelId = localStorage.getItem('SelectedAgent')
    getEvaluatRredDot({ channelId }).then((res) => {
      commit('updateState', {
        name: 'menusStatistics',
        value: { handleHgt: res.data },
      })
    })
  }
  if (getPlatform() === 'console-admin') {
    getFlowNum({ body: attachServiceChargeFlow }).then((res) => {
      commit('updateState', {
        name: 'menusStatistics',
        value: {
          ...res.data,
        },
      })
    })
  }
  if (getPlatform() === 'console-company') {
    const companyID = Cookies.get('x-sec-subject-company-id') || Cookies.get('x-sec-lvl1subject-app-id')
    companyID && getTaskRedDot({ companyID }).then(({ data }) => {
      commit('updateState', {
        name: 'menusStatistics',
        value: { openHgtRedot: data },
      })
    })
  }
}
