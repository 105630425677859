<template>
  <a-config-provider :locale="locale">
    <div class="alone" :class="`template-${platformType}`">
      <router-view v-if="isRouterAlive" :loading="loading" :content="content" />
      <wechat-hint v-if="isWindowsWechat" />
      <a-button
        v-if="!needTip"
        class="feedback-icon"
        type="primary"
        size="large"
        @click="handelFeedbackClick"
        ><a-icon type="question"
      /></a-button>
      <feedback-modal :visible.sync="showModal" @submit="feedback" />
      <pending-mask
        :show-mask.sync="showMask"
        :status="maskStatus"
        @afterClose="afterClose"
      />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { mapState, mapActions, mapMutations } from 'vuex'
import platformEnum from '@/utils/platformEnum'
import { getBrowserInfo, getBrowserVersionInfo } from './utils/browserTip'
import wechatHint from './components/wechat.vue'
import feedbackModal from '@/components/mainFrame/feedbackModal.vue'
import pendingMask from '@/components/mainFrame/pendingMask.vue'
import Record from '@/utils/record'
import { getChosen } from '@/utils/auth'
import { v4 } from 'uuid'
import Cookies from 'js-cookie'

const hiddenEnums = ['hidden', 'mozHidden', 'msHidden', 'webkitHidden']
const visibilityChangeEnums = [
  'visibilitychange',
  'mozvisibilitychange',
  'msvisibilitychange',
  'webkitvisibilitychange',
]
const index = hiddenEnums.findIndex(
  (proto) => typeof document[proto] !== 'undefined',
)
const hidden = hiddenEnums[index]
const visibilityChange = visibilityChangeEnums[index]

export default {
  name: 'framework',
  provide() {
    return {
      reload: this.reload,
    }
  },
  components: {
    'wechat-hint': wechatHint,
    feedbackModal,
    pendingMask,
  },
  props: {
    loading: Boolean,
    content: String,
  },
  computed: {
    ...mapState([
      'userInformation',
      'platformType',
      'inPartWhiteList',
      'inWholeWhiteList',
      'menus',
      'indexPath',
      'showMask',
      'maskStatus',
      'oem',
      'currentChosen',
    ]),
    platformEnum() {
      return platformEnum
    },
    theme() {
      const { theme } = platformEnum[this.platformType] || { theme: 'dark' }
      return theme || 'dark'
    },
  },
  data() {
    return {
      isRouterAlive: true,
      locale: zhCN,
      needTip: false,
      isWindowsWechat: false,
      record: '',
      showModal: false,
      isChrome: false,
    }
  },
  created() {
    if (hidden && visibilityChange) {
      document.addEventListener(visibilityChange, this.checkAuth, false)
    }
    const sys = getBrowserInfo()
    const { browser, ver } = sys
    this.isChrome = browser === 'chrome'
    window.__isChrome__ = this.isChrome
    // 不支持的浏览器跳转引导下载浏览器页面
    this.needTip = Boolean(
      (browser === 'chrome' && ver.split('.')[0] < 61) || browser === 'trident',
    )
    this.isWindowsWechat = browser === 'windowswechat'
    // 登陆页面、引导下载浏览器页面不请求菜单接口
    const { pathname } = window.location
    // 跳转引导下载浏览器页面
    const options = getBrowserVersionInfo()
    if (!this.isWindowsWechat && this.needTip) {
      this.$router.replace('/guide')
      this.$report('浏览器引导页-弹窗', '', options)
    } else {
      this.$report('浏览器引导页-不弹窗', '', options)
      // 如果当前浏览器支持，且页面为引导下载页，跳回首页，并请求菜单
      if (pathname.includes('/guide')) {
        const platform = localStorage.getItem('platformType')
        !platform && this.$router.replace({ name: 'login' })
        platform && this.$router.replace('/') && this.reload()
      }
      // 必有常规操作
      this.usualOp()
    }
  },
  async mounted() {
    // 实例化之后开始录制
    if (this.isChrome) {
      this.record = new Record()
      window.__record__ = this.record
      await this.record.init()
      this.record.startRecord()
    }
  },
  beforeDestroy() {
    document.removeEventListener(visibilityChange, this.checkAuth)
  },
  methods: {
    ...mapActions(['getOem', 'getMenu']),
    ...mapMutations(['updateState']),
    checkAuth() {
      // 不需要鉴权的页面才做此判断
      if (!document[hidden] && !this.inWholeWhiteList) {
        // 对比profile是否一致
        const localProfile = window.localStorage.getItem('userProfiles')
        const cookieProfile = Cookies.get('x-sec-profile')
        const profileChanged = localProfile !== cookieProfile

        console.log(localProfile, cookieProfile)

        const localChosen = window.localStorage.getItem('currentChosen') || false
        // 有切换的平台，切换了currentChosen的id
        const currentChosen = JSON.parse(localChosen || '{}')
        const { platformType, id } = currentChosen
        const currentChosenChanged = localChosen
          && platformType === this.platformType
          && id - 0 !== this.currentChosen.id - 0

        console.log(id, this.currentChosen.id)

        // 对比USERID
        const localUserId = window.localStorage.getItem('userId')
        const cookieUserId = Cookies.get('AYG_USERID')
        const userChanged = localUserId - 0 !== cookieUserId - 0

        console.log(localUserId, cookieUserId)

        if (profileChanged || currentChosenChanged || userChanged) {
          this.updateState({ name: 'currentChosen', value: currentChosen })
          platformType === 'console-company'
            && this.updateState({
              name: 'userContext',
              value: localChosen
                ? JSON.parse(window.localStorage.getItem('userContext'))
                : '',
            })
          this.reload()
        }
      }
    },
    async reload() {
      console.log('reload')
      // 需要重载，发布事件重载子项目
      // 跳转默认页面
      this.jumpDefaultMenu(true)
      // window.myBus.emit('reload')
    },
    async jumpDefaultMenu(jump) {
      const loginPage = this.$route.name === 'login'
      const { pathname } = window.location
      // 有首页且（登陆页/非白名单页面）且非已注册子项目页面，则跳转首页
      if (this.indexPath) {
        if (
          (([undefined, '', '/', '/micro-admin', '/micro-admin/main'].includes(
            pathname,
          )
            || loginPage
            || this.$registeredApps.includes(pathname.replace('/micro-', '')))
            && this.$router.name !== '404')
          || jump
        ) {
          window.location.replace(this.indexPath)
        }
      } else {
        // 没有请求菜单
        await this.getMenu()
        this.indexPath && window.location.replace(this.indexPath)
      }
      document.title = this.oem.platformName || platformEnum[this.platformType].logoName
    },
    // 常规操作
    usualOp() {
      // 获取oem
      this.getOem()
      this.updateState({
        name: 'currentChosen',
        value: getChosen(),
      })
      const userContext = localStorage.getItem('userContext')
      this.updateState({
        name: 'userContext',
        value: userContext ? JSON.parse(userContext) : '',
      })
      // 白名单页面不鉴权
      !this.inWholeWhiteList && this.getMenu()
      const that = this
      // 子项目无权限需要跳转登陆页面时
      window.myBus.on('login', () => {
        that.$router.replace('/login')
      })
      // 重新获取代办数，子项目通过调用来触发
      window.myBus.on('getStatisticsInfo', () => {
        this.$store.dispatch('getStatisticsInfo')
      })
      window.myBus.on('getApplyInfo', () => {
        this.$store.dispatch('getApplyInfo')
      })
      // 登陆成功后
      window.myBus.on('loginOk', () => {
        // 现在是获取菜单之后都会触发跳转默认菜单的方法ORZ
        this.jumpDefaultMenu()
        this.updateState({ name: 'loginLoading', value: false })
      })
    },
    // 点击反馈的时候就停止录制
    handelFeedbackClick() {
      if (this.isChrome) {
        this.record.stopRecord()
      }
      this.showModal = true
    },
    // 关闭弹层之后
    afterClose() {
      if (this.isChrome) {
        this.record.startRecord()
      }
    },
    // 提交反馈
    async feedback(values) {
      this.updateState({
        name: 'showMask',
        value: true,
      })
      this.updateState({
        name: 'maskStatus',
        value: 'pending',
      })

      let recordResult = {}
      if (this.isChrome) {
        const record = await this.record.getRecord()
        const params = { code: 200, data: record }
        const blob = new Blob([JSON.stringify(params, null, 2)], {
          type: 'application/json',
        })
        const fileName = `用户反馈记录json.${v4()}.txt`
        recordResult = await this.$hmUtils.uploadOSS(blob, {}, fileName)
      }

      values.loginName = values.loginName || this.userInformation.name

      this.$post('/api/backend-monitor-system/feedback/create', {
        description: values.description,
        loginName: values.loginName,
        domain: window.location.host,
        ua: window.navigator.userAgent,
        operationDownloadCode: recordResult.downloadCode,
        initiative: true,
        imageDownloadCodes: values.downloadCodes.map((el) => el.downloadCode),
      })
        .then(() => {
          this.updateState({
            name: 'maskStatus',
            value: 'success',
          })
        })
        .catch(() => {
          this.updateState({
            name: 'maskStatus',
            value: 'fail',
          })
        })
        .finally(() => {
          // 重启录制
          // reStart()
          // this.record.recording = true
        })
    },
  },
}
</script>

<style lang="scss">
@import "./style/color";

body {
  margin: 0;
  color: #606266;
  --white: #ffffff;
  --blur: #108ee9;
  ::-webkit-scrollbar {
    width: 6px; /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
    height: 6px;
  }
  /*定义滚动条轨道（凹槽）样式*/
  ::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  /*定义滑块 样式*/
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    height: 100px; /* 滚动条滑块长度 */
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.alone {
  height: 100%;
}
.feedback-icon {
  position: fixed;
  right: 0;
  bottom: 150px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 2020;
  &:hover {
    &::after {
      content: " 问题反馈";
    }
  }
}
</style>
