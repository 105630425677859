<template>
  <div :style="style" ref="lavContainer" />
</template>
<script>
import lottie from 'lottie-web'

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    height: Number,
    width: Number,
  },
  data() {
    return {
      anim: '',
    }
  },
  computed: {
    style() {
      return {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto',
      }
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler(nv) {
        // 删除掉旧的动画再挂载新的
        this.anim && this.anim.destroy()
        this.$nextTick(() => {
          this.showAnimation(nv)
        })
      },
    },
  },
  methods: {
    showAnimation(options) {
      this.anim = lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: 'svg',
        loop: options.loop || false,
        autoplay: options.autoplay || false,
        animationData: options.animationData,
        rendererSettings: options.rendererSettings || {},
      })
      this.$emit('animCreated', this.anim)
    },
  },
}
</script>
