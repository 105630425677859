import {
  getUserId,
  getPlatform,
} from './auth'

import * as packageJson from '../../package.json'

const { name, version } = packageJson
// 数据库中存储长度有线，仅仅36个字节
const mainName = name.split('-').pop()

const USERID = getUserId()
const PlatformType = getPlatform()

window.localStorage.wmUserInfo = JSON.stringify({
  userId: USERID,
  userTag: PlatformType,
  projectVersion: `${mainName}-${version}`,
})
