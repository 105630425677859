/*
 ** 子项目入口的url由域名+path组成，appEntryPublicPath代表的就是各个子项目对应的path
 ** 该内容存储在.env文件中
 */
const appEntryPublicPath = {
  asr: process.env.VUE_APP_MICRO_ASR,
  open: process.env.VUE_APP_MICRO_OPEN,
  // open: '//microfrontendstest92.aiyuangong.com/micro-open/',
  agent: process.env.VUE_APP_MICRO_AGENT,
  risk: process.env.VUE_APP_MICRO_RISK,
  // risk: '//microfrontendstest92.aiyuangong.com/micro-risk/',
  provider: process.env.VUE_APP_MICRO_PROVIDER,
  // provider: '//microfrontendstest92.aiyuangong.com/micro-provider/',
  admin: process.env.VUE_APP_MICRO_ADMIN, // console-admin
  // admin: '//localhost:5022/micro-admin/', // console-admin
  // settlement: process.env.VUE_APP_MICRO_SETTLEMENT, // settlement
  settlement: process.env.VUE_APP_MICRO_SETTLEMENT, // settlement
  'website-admin': process.env.VUE_APP_MICRO_WEBSITE,
  oem: process.env.VUE_APP_MICRO_OEM,
  permission: process.env.VUE_APP_MICRO_PERMISSION,
  contract: process.env.VUE_APP_MICRO_CONTRACT,
  customer: process.env.VUE_APP_MICRO_CUSTOMER,
  others: process.env.VUE_APP_MICRO_OTHERS,
  distribution: process.env.VUE_APP_MICRO_DISTRIBUTION,
  payment: process.env.VUE_APP_MICRO_PAYMENT,
  // payment: '//microfrontendstest92.aiyuangong.com/micro-payment/',
  'provider-manage': process.env.VUE_APP_MICRO_PROVIDER_MANAGE,
  invoice: process.env.VUE_APP_MICRO_INVOICE,
  'kpi-manage': process.env.VUE_APP_MICRO_KPI_MANAGE,
  tenant: process.env.VUE_APP_MICRO_TENANT,
  tools: process.env.VUE_APP_MICRO_TOOLS,
  scrm: process.env.VUE_APP_MICRO_SCRM,
}
// 子项目的域名在production模式下是统一的，只有在development下是不同的，只需要根据环境不同取不同的值即可
const appEntryDomain = process.env.VUE_APP_MICRO_DOMAIN
let appEntry = {}
if (process.env.NODE_ENV === 'development') {
  appEntry = appEntryPublicPath // 本地开发环境直接取.env.development里配置的入口完整路径
} else {
  appEntry = Object.keys(appEntryPublicPath).reduce((acc, key) => {
    acc[key] = appEntryDomain + appEntryPublicPath[key]
    return acc
  }, {})
}
console.log('appEntry:', appEntry)
export const apps = (render, props) => Object.keys(appEntry).map((item) => ({
  name: `micro-${item}`,
  entry: appEntry[item],
  render,
  // activeRule: (location) => location.pathname === `/micro-${item}`,
  activeRule: (location) => location.pathname.split('/')[1] === `micro-${item}`,
  props,
}))

export const appArray = Object.keys(appEntryPublicPath)
