<template>
  <div class="channel-select" v-if="!$store.state.innerUser">
    <!--  :trigger="['click']" -->
    <div style="float: left;">
      <a-button
        v-if="domain"
        type="primary"
        style="background: #3174f8; border-radius: 14px; margin-left: 24px;"
        :href="`${protocol}://${domain}`"
        target="_blank"
      >
        专属官网
        <a-icon type="link" />
      </a-button>
    </div>
    <div style="float: right; display: flex; align-items: center;">
      <a-dropdown placement="bottomCenter">
        <div>
          <a-icon type="phone" />
        </div>
        <a-menu slot="overlay">
          <a-menu-item>
            <a href="javascript:;">18300019766</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-divider type="vertical" />
      <a-dropdown>
        <div>
          <span class="channel-text">{{ selectedChannelName }} </span>
          <!-- <a-button v-if="channels.length > 1"
                    size="small"
                    type="primary"
                    style="marginLeft: 10px;borderRadius: 32px">
            切换
            <a-icon type="swap" />
            </a-button> -->
          <a-icon type="down" />
        </div>
        <a-menu slot="overlay">
          <a-menu-item
            v-for="item in channels"
            :key="item.id"
            @click="changeChannel(item.id)"
          >
            <a
              href="javascript:;"
              :style="{ color: selectedChannel === item.id ? '#178FFF' : '' }"
            >
              {{ item.name }}</a
            >
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <upgradeGuide  v-if="showGuide" @close="showGuide = false" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { setSelectedAgent, setSelectedChannel } from '@/utils/auth'
import upgradeGuide from '../upgradeGuide.vue'

export default {
  components: { upgradeGuide },
  data() {
    return {
      selectedChannel: this.$store.state.selectedChannel,
      domain: '',
      protocol: '',
      showGuide: false, // 显示升级模板
    }
  },
  inject: ['reload'],
  computed: {
    ...mapState(['channels', 'platformType', 'userInformation']),
    // 遍历找到代理商name，并设置当前代理商的id
    selectedChannelName() {
      let channelName = ''
      for (let i = 0; i < this.channels.length; i += 1) {
        if (this.channels[i].id === this.selectedChannel) {
          channelName = this.channels[i].name
          // 缓存一下代理商id，入驻的时候用的
          setSelectedAgent(this.channels[i].subjectId)
          break
        }
      }
      return channelName
    },
  },
  watch: {
    // 获取菜单之后更新数据
    userInformation: {
      immediate: true,
      handler(val) {
        if (val) {
          const { roles } = val.userProfile
          if (roles.some((el) => el.name === '租户管理员')) {
            this.showGuide = Boolean(Number(localStorage.getItem('showGuide')) < 3)
          }
        }
      },
    },
  },
  mounted() {
    this.getDomain()
  },
  methods: {
    ...mapActions(['getMenu']),
    changeChannel(profileId) {
      // 如果id一样啥都不干
      if (profileId === this.selectedChannel) {
        return
      }
      console.log(profileId, document.cookie, 'before,changeChannel')
      // 储存选中的渠道
      setSelectedChannel(profileId)
      console.log(document.cookie, 'changeChannel')
      this.selectedChannel = profileId

      const channelInfo = this.channels.filter((item) => item.id === this.selectedChannel)
      console.log('channelInfo', channelInfo)
      const tenantId = channelInfo[0].subjectId
      this.getDomain(tenantId) // 重新获取官网链接
      this.getMenu() // 重新获取菜单
      console.log(this.$route.path)
      // 如果是在首页，强制刷新
      if (
        this.$route.path === '/micro-agent/home'
        || this.$route.path === '/micro-tenant/dashboard'
      ) {
        this.reload()
        return
      }
      // 租户端跳去的首页不一样
      if (this.platformType === 'console-tenant') {
        this.$router.push({
          path: '/micro-tenant/dashboard',
        })
        return
      }
      // 返回去首页
      this.$router.push({ path: '/micro-agent/home' })
    },
    getDomain(tenantId) {
      console.log(tenantId)
      const url = '/api/sysmgr-web/oem/platform-oem-detail'
      const params = {
        tenantId: tenantId || localStorage.getItem('SelectedAgent'),
        oemLevel: 1,
        platformType: 'console-main-tenant',
      }
      this.$post(url, params).then(({ data }) => {
        this.domain = data.domain
        this.protocol = data.protocol
      })
    },
  },
}
</script>

<style scoped>
.channel-select {
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
  flex: 1;
}
</style>
