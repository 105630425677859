import Vue from 'vue'
import Vuex from 'vuex'
import platformEnum from '@/utils/platformEnum'
import {
  setPlatform,
  removePlatform,
  getSelectedChannel,
  getPlatform,
  getChannels,
  setInnerUser,
} from '@/utils/auth'
import { getMenusStatistics } from '@/utils/getFlowNum'
import Cookies from 'js-cookie'

const vm = new Vue()

Vue.use(Vuex)

const store = {
  state: {
    userInformation: false,
    platformType: getPlatform(),
    channels: getChannels(),
    selectedChannel: getSelectedChannel(),
    companyInformation: [], // 客户账号的公司
    menusStatistics: {}, // 代办事项数目
    menus: [], // 菜单列表
    permissions: {}, // 权限列表
    oem: '', // 里面有平台名称
    innerUser: false, // 是否内部用户
    menuCollapsed: false, // 菜单折叠
    pageFound: true, // 是否加载到了页面
    inPartWhiteList: false,
    inWholeWhiteList: false,
    inRecordWhiteList: false, // 录屏白名单
    loginLoading: false, // 登录按钮loading状态
    viewCompanyPermission: false, // 是否有公司权限，有可以查询发票接口，无则不查询发票接口
    indexPath: '', // 首页路由
    currentChosen: {}, // 当前选择企业/操作对象
    showMask: false, // 反馈蒙层
    maskStatus: '', // 蒙层状态 success：成功；pending：提交中；fail：失败
    userContext: '', // open端UserContext
    reloading: true, // 获取菜单时reloading
  },
  mutations: {
    // 万能操作
    updateState(state, { name, value }) {
      state[name] = value
    },
    // 菜单一波操作
    SET_MENU(state, payload) {
      state.menus = payload
      const firstMenu = payload[0]
      const hasChildren = firstMenu.children && firstMenu.children.length
      const path = hasChildren
        ? `/micro-${firstMenu.children[0].action}`
        : `/micro-${firstMenu.action}`
      // 首页路径
      state.indexPath = path
      // 获取菜单成功之后触发loginOk
      window.myBus.emit('loginOk')
    },
    // 权限列表
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload
    },
    // 登录一波操作
    LOGIN_ACTION(state, payload) {
      /**
       * 登录接口统一后，需要存储的应该也是一样的了,这个要找子项目的负责人统一，我建议用长久储存
       */
      setPlatform(payload.platformType) // 当前子项目
      state.platformType = payload.platformType
      localStorage.setItem('isLogin', true)
      localStorage.setItem('userId', payload.userId) // 当前用户id
      Cookies.set('AYG_USERID', payload.userId)
      state.channels = payload.userProfiles || [] // 账号下的渠道列表
      const { id } = payload.userProfiles[0] || {}
      id && Cookies.set('x-sec-profile', id) // 下载之类的请求走的不是ajax，只能这样写
      // 分子项目做不同的操作
      if (payload.platformType && platformEnum[payload.platformType].loginOk) {
        platformEnum[payload.platformType].loginOk(state, payload)
      }
    },
    // 登出一波操作
    LOG_OUT(state) {
      const { platformType } = state
      // 分子项目做不同的操作
      if (platformEnum[platformType]?.logout) {
        platformEnum[platformType].logout(state)
      }
      state.userInformation = ''
      state.menus = []
      state.platformType = ''
      removePlatform()
      localStorage.removeItem('userContext')
      localStorage.removeItem('currentChosen')
      localStorage.removeItem('isLogin')
      sessionStorage.removeItem('WXAuthCode')
      sessionStorage.removeItem('DID')
      sessionStorage.removeItem('currentRow')
      localStorage.removeItem('headerConfig')
      window.myBus.emit('login')
    },
    // 获取菜单后存储user信息
    SET_USERINFORMATION(state, user) {
      state.userInformation = user
    },
  },
  actions: {
    async getOem({ commit }) {
      const { data } = (await vm.$get(
        `/api/sysmgr-web/oem/query-oem-config-by-domain?domain=${window.location.host}`,
      )) || { data: '' }
      const { platformName } = data
      const { logoName } = platformEnum[getPlatform()] || {
        logoName: '欢迎登录',
      }
      document.title = platformName || logoName
      commit('updateState', {
        name: 'oem',
        value: data || '',
      })
      // 动态设置网站ico
      const link = document.querySelector('link[rel*=\'icon\']')
        || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'icon'
      link.href = data && data.logoCode
        ? `/api/sysmgr-web/file/download?downloadCode=${data.logoCode}`
        : '/favicon.ico'
      document.getElementsByTagName('head')[0].appendChild(link)
      window.myBus.emit('getOemSuccess')
    },
    async getCssLocation() {
      const res = await vm.$post(
        '/api/identitymgt/sys-common/select/menu-icon-css-path',
      )
      const { data } = res || { data: '' }
      const links = Array.from(document.getElementsByTagName('link'))
      if (data) {
        const alreadyHave = links.find((el) => el.href === data)
        if (!alreadyHave) {
          // 增加style标签
          const ss = document.createElement('link')
          ss.type = 'text/css'
          ss.rel = 'stylesheet'
          ss.href = data
          document.getElementsByTagName('head')[0].appendChild(ss)
        }
      }
    },
    // 获取菜单
    async getMenu({ commit, dispatch, state }) {
      commit('updateState', { name: 'reloading', value: true })
      const params = {}
      const platformType = getPlatform()
      const isOpen = platformType === 'console-company'
      if (isOpen) {
        params.customCompanyId = state.userContext?.lvl1SubjectId || state.userContext?.subjectId
      }
      params.menuType = platformType
      try {
        const res = await vm.$get('/api/sysmgr-web/auth/principal', params)
        dispatch('getCssLocation')
        // 获取是否内部用户
        console.log('before check')
        await dispatch('checkInner')
        console.log('after check', state.innerUser)
        isOpen && (await dispatch('getProfiles'))
        const { code } = res
        if (code === 200) {
          const { user, permissions, menus } = res.data
          if (!menus || !menus.length) {
            // 菜单为空，不做跳转，弹出提示。
            vm.$warning({
              title: '提示',
              content: '对不起，当前登陆账号并无可操作菜单，请联系管理员。',
            })
            return
          }
          // 这里针对风控的菜单做一个缓存处理
          const riskIndex = menus.findIndex((el) => el.menuId === 64100)
          if (riskIndex > -1) {
            localStorage.setItem('riskMenus', JSON.stringify(menus[riskIndex]))
            menus[riskIndex].children = []
          }
          commit('SET_MENU', menus)
          commit('SET_USERINFORMATION', user)
          commit('SET_PERMISSIONS', permissions)
          getMenusStatistics(commit)
          localStorage.setItem('permissionsData', JSON.stringify(permissions))
        }
      } catch (err) {
        console.error(err)
        commit('updateState', { name: 'loginLoading', value: false })
      } finally {
        commit('updateState', { name: 'reloading', value: false })
      }
    },
    async checkInner({ commit }) {
      const { data } = (await vm.$get(
        '/api/agent-bff/user/inner-user-check',
      )) || { data: { innerUser: false } }
      setInnerUser(data.innerUser)
      commit('updateState', {
        name: 'innerUser',
        value: data.innerUser,
      })
      data.innerUser
        && commit('updateState', {
          name: 'viewCompanyPermission',
          value: true,
        })
    },
    // 获取代办的数量
    getStatisticsInfo({ commit }) {
      getMenusStatistics(commit)
    },
    // 获取录屏白名单
    async getWhiteList({ commit, state }) {
      const { data } = await vm.$post(
        '/api/backend-monitor-system/whitelist/find',
        {
          loginName: state.userInformation.name,
          domain: window.location.host,
        },
      )
      commit('updateState', {
        name: 'inRecordWhiteList',
        value: data.whitelists.length > 0,
      })
    },
    // 发送录屏记录
    sendRecord({ state }, record) {
      if (state.inRecordWhiteList) {
        vm.$post('/api/backend-monitor-system/feedback/create', {
          loginName: state.userInformation.name,
          domain: window.location.host,
          operations: record.map((el) => ({
            events: el.events,
            operationId: el.operationId,
          })),
          initiative: false,
          ua: window.navigator.userAgent,
        })
      }
    },
    // 获取用户登录平台的权限
    async getProfiles({ commit }) {
      const { data } = (await vm.$post(
        '/api/identitymgt/query-login-user-profiles',
      )) || { data: [] }
      commit('updateState', { name: 'companyInformation', value: data || [] })
    },
  },
}

export default new Vuex.Store(store)
