<template>
  <span>
    <a href="#" @click="openSwitchModal" class="ant-dropdown-link">
      {{ currentCompanyName }}
      <a-icon v-if="mutipleChoice" type="down" />
    </a>
    <switch-profile :visible.sync="showSwitchModal" :change="true" />
    <ScrmSwitchUserLogin :visible.sync="showScrmDialog" />
  </span>
</template>

<script>
import switchProfile from '@/components/switchProfile/index.vue'
import ScrmSwitchUserLogin from '@/components/ScrmSwitchUserLogin/index.vue'
import { getAgents, getChannels, getProviders } from '@/utils/auth'
import { mapState } from 'vuex'

const companiesFunctions = {
  'console-provider': getProviders,
  'console-agent': getAgents,
  'console-scrm': getAgents,
}

export default {
  components: {
    switchProfile,
    ScrmSwitchUserLogin,
  },
  data() {
    return {
      showSwitchModal: false,
      showScrmDialog: false,
    }
  },
  computed: {
    ...mapState(['platformType', 'currentChosen']),
    // 显示切换profile弹窗入口
    showSwith() {
      return ['console-agent', 'console-provider', 'console-scrm'].includes(this.platformType)
    },
    currentCompanyName() {
      if (!this.showSwith) return ''
      const { platformType, name } = this.currentChosen
      const beenSet = platformType === this.platformType
      return beenSet ? name : ''
    },
    userProfiles() {
      const platfumStorageEnum = {
        'console-provider': getProviders,
        'console-agent': getChannels,
        'console-scrm': getChannels,
      }
      if (!(this.platformType in platfumStorageEnum)) return []
      return platfumStorageEnum[this.platformType].call() || []
    },
    mutipleChoice() {
      const include = this.platformType in companiesFunctions
      if (!include) return false
      return companiesFunctions[this.platformType].call().length > 1 || this.userProfiles.length > 1
    },
  },
  methods: {
    openSwitchModal(e) {
      e && e.preventDefault()
      if (this.platformType === 'console-scrm') {
        this.showScrmDialog = this.mutipleChoice
      } else {
        this.showSwitchModal = this.mutipleChoice
      }
    },
  },
}
</script>

<style scoped>
.ant-dropdown-link {
  color: rgba(0, 0, 0, 0.65);
}
</style>
