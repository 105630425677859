// 白名单内的路由鉴权

// 这个列表里的路由要显示侧边栏和顶部
const part = [
  // '/404' // 404页面要显示侧边栏，鉴权。
]
// 这个列表里的路由不显示侧边栏和顶部
const whole = [
  '/login',
  '/guide',
  '/micro-risk/hgt/fileUpload',
  '/micro-risk/hgt/appraisal/preview',
  '/micro-risk/censor/attachment',
  '/micro-tenant/register',
  '/micro-tenant/result',
  '/micro-scrm/agent-register',
  // chrome语音识别插件
  '/micro-tools/voice-identify/list',
]

export default { part, whole }
