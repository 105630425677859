<template>
  <a-modal
    :visible="show"
    title="切换"
    :footer="null"
    :mask-closable="false"
    @cancel="closeModal"
  >
    <a-form layout="inline">
      <a-form-item label="企业" name="subjectId">
        <ayg-select
          v-model="currentChosen"
          :props="{
            value: 'id',
            label: 'subjectName',
          }"
          :data="companyInformation"
          @change="changeIndustry"
        />
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :data-source="industries"
      :pagination="pagination"
      :scroll="{ y: 300 }"
      :loading="loading"
      row-key="subjectId"
      @change="tableChange"
    >
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          :disabled="record.subjectId === userContext.subjectId"
          @click="$emit('change', record)"
          >切换</a-button
        >
      </span>
    </a-table>
  </a-modal>
</template>
<script>
import { mapState } from 'vuex'
import Cookies from 'js-cookie'

export default {
  props: {
    showModal: Boolean,
    companies: Array,
    industries: Array,
    type: String,
    page: Number,
    pageSize: Number,
    total: Number,
    loading: Boolean,
  },
  data() {
    return {
      columns: [
        {
          dataIndex: 'subjectName',
          title: '商户名称',
        },
        {
          dataIndex: 'subjectId',
          title: '操作',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      id: 0,
      currentChosen: '',
      originalProfile: {},
    }
  },
  computed: {
    ...mapState(['companyInformation', 'userContext']),
    current: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      },
    },
    show: {
      get() {
        return this.showModal
      },
      set(val) {
        if (!val) {
          this.$emit('query', { subjectId: '' })
        }
        this.$emit('update:showModal', val)
      },
    },
    pagination() {
      const { current, pageSize, total } = this
      return {
        current,
        pageSize,
        size: 'small',
        total,
        showTotal: (number) => `共${number}条`,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
      }
    },
  },
  watch: {
    showModal() {
      if (this.show) {
        const headerConfig = JSON.parse(
          localStorage.getItem('headerConfig') || '{}',
        )
        this.originalProfile = headerConfig
        this.id = Cookies.get('x-sec-profile')
        if (this.id && this.companyInformation.length) {
          const { subjectName } = this.companyInformation.find((el) => el.id - 0 === this.id - 0)
            || {}
          this.currentChosen = subjectName
          this.changeIndustry(this.id)
        }
      }
    },
  },
  methods: {
    tableChange(pagination) {
      const { current, pageSize } = pagination
      const { id } = this
      this.$emit('query', { page: current, pageSize, userProfileId: `${id}` })
    },
    // 修改企业的时候更改userProfiles
    changeIndustry(id) {
      this.id = id
      this.$emit('query', {
        page: 1,
        userProfileId: `${id}`,
      })
    },
    closeModal() {
      const headerConfig = JSON.parse(
        localStorage.getItem('headerConfig') || '{}',
      )
      const id = headerConfig['x-sec-profile']
      const originalId = this.originalProfile['x-sec-profile']
      if (
        `${id}` !== `${originalId}`
        && !['null', 'undefined'].includes(`${originalId}`)
      ) {
        window.localStorage.setItem('userProfiles', originalId)
        Cookies.set('x-sec-profile', originalId)
        Cookies.set('redash-profile', originalId)
        localStorage.setItem(
          'headerConfig',
          JSON.stringify(this.originalProfile),
        )
      }
      this.show = false
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form {
  margin-bottom: 10px;
  .ant-select {
    min-width: 200px;
    height: fit-content;
  }
}
</style>
