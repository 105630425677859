<template>
  <div v-if="showMask" class="pending-mask">
    <section class="description">
      <p class="icon-case">
        <lottie
          :options="animationOptions"
          :width="300"
          :height="300"
          @animCreated="handleAnimation" />
      </p>
      <b>{{ title }}</b>
      <p>{{ subTitle }}</p>
      <a-button
        v-if="status !== 'pending'"
        type="primary"
        size="large"
        @click="afterClose"
      >{{ status === 'success' ? '好的' : '知道了' }}</a-button>
    </section>
  </div>
</template>
<script>
import lottie from '@/components/lottie.vue'
import * as pendingLoading from '@/assets/loading.json'
import * as successAnim from '@/assets/submit-smile.json'
import * as errorAnim from '@/assets/no-connection-animation.json'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    lottie,
  },
  data() {
    return {
      statusMap: {
        success: {
          title: '提交成功',
          subTitle: '我们已收到您的反馈，感谢您的支持',
          animationData: successAnim.default,
        },
        pending: {
          title: '正在智能分析您的系统',
          subTitle: '请稍等',
          animationData: pendingLoading.default,
        },
        fail: {
          title: '提交失败',
          subTitle: '',
          animationData: errorAnim.default,
        },
      },
      anim: '',
      showAnim: true,
    }
  },
  computed: {
    ...mapState(['showMask', 'maskStatus']),
    status() {
      return this.maskStatus
    },
    title() {
      return this.statusMap[this.status].title
    },
    subTitle() {
      return this.statusMap[this.status].subTitle
    },
    animationOptions() {
      return {
        animationData: this.statusMap[this.status].animationData,
        autoplay: true,
        loop: true,
      }
    },
  },
  methods: {
    ...mapMutations(['updateState']),
    handleAnimation(anim) {
      this.anim = anim
      this.anim.play()
    },
    afterClose() {
      this.updateState({
        name: 'showMask',
        value: false,
      })
      this.updateState({
        name: 'maskStatus',
        value: '',
      })
      this.$emit('afterClose')
    },
  },
}
</script>
<style lang="scss" scoped>
.pending-mask {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  text-align: center;
  min-height: 100vh;
  box-sizing: border-box;
  .description {
    margin: auto;
    color: #fff;
    font-size: 32px;
    p {
      font-size: 24px;
    }
  }
}
</style>
