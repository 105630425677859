// 统一对返回结果做处理
const checkCode = (data) => {
  // if (data.code === 401) {
  //   const { pathname } = window.location
  //   if (pathname !== '/login') {
  //     return window.myBus.emit('login')
  //   }
  //   return null
  // }
  if (data.code >= 200 && data.code < 300) {
    return data
  }
  return Promise.reject(data)
}

function getHeaders() {
  const headers = {}
  let userContext = window.localStorage.getItem('userContext')
  if (userContext) {
    userContext = JSON.parse(userContext)
    const {
      lvl1SubjectId, subjectId, subjectName, lvl1SubjectName,
    } = userContext
    if (lvl1SubjectId) {
      Object.assign(headers, {
        'x-sec-subject-app-id': subjectId,
        'x-sec-subject-app-name': encodeURI(subjectName),
        'x-sec-lvl1subject-app-id': lvl1SubjectId,
        'x-sec-vlv1subject-app-name': encodeURI(lvl1SubjectName),
      })
    } else {
      Object.assign(headers, {
        'x-sec-subject-company-id': subjectId,
        'x-sec-subject-company-name': encodeURI(subjectName),
      })
    }
  }
  // 善赚营销平台
  const platformType = window.localStorage.getItem('platformType')
  if (platformType === 'console-scrm') {
    let scrmConfig = window.localStorage.getItem('headerConfig')
    if (scrmConfig) {
      scrmConfig = JSON.parse(scrmConfig)
      if (scrmConfig['x-sec-subject-scrm-id']) {
        Object.assign(headers, {
          'x-sec-subject-agent-id': scrmConfig['x-sec-subject-scrm-id'],
        })
      }
    }
  }
  return headers
}

export {
  checkCode,
  getHeaders,
}
