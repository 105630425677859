import * as rrweb from 'rrweb'
import { openDB } from 'idb'

const storeName = 'records'

class Record {
  constructor() {
    this.db = null
    this.events = [] // 视频数据
    this.isSaving = false
  }

  // 初始化工作
  async init() {
    this.db = await openDB('Feedback', 1, {
      upgrade(db) {
        // Create a store of objects
        db.createObjectStore(storeName, {
          // The 'id' property of the object will be the key.
          keyPath: 'id',
          // If it isn't explicitly set, create a value by auto incrementing.
          autoIncrement: true,
        })
      },
    })
  }

  // 开始录制
  startRecord() {
    const self = this
    this.stopFn = rrweb.record({
      async emit(event) {
        // 当事件数量 达到限制就入库
        if (self.events.length >= 500 && self.isSaving === false) {
          self.isSaving = true
          await self.stopRecord()
          self.startRecord()
        } else {
          self.events.push(event)
        }
      },
    })
  }

  // 停止录制
  async stopRecord() {
    // 判断events 大于2条才入库
    if (this.events.length > 2) {
      const saveEvents = Object.assign(this.events)
      this.events = []
      await this.saveData(saveEvents)
    }
    this.stopFn()
  }

  // 入库操作
  async saveData(events) {
    // 删除一些旧数据
    const keys = await this.db.getAllKeys(storeName)
    const saveCount = 2
    if (keys.length > saveCount) {
      console.log('删除一些旧数据')

      await this.db.delete(storeName, IDBKeyRange.upperBound(keys[keys.length - saveCount]))
    }
    await this.db.add(storeName, events)
    this.isSaving = false
  }

  // 获取记录
  async getRecord() {
    const records = await this.db.getAll(storeName)
    return records
  }
}

export default Record
