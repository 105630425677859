const TokenKey = 'accessToken'
const SelectedChannelKey = 'selectedChannel'
const SelectedAgentKey = 'SelectedAgent'
const ChannelsKey = 'channels'
const PlatformKey = 'platformType'
const InnerUserKey = 'innerUser'
const UserIDKey = 'userId'
const SelectedProviderKey = 'selectedProvider'
const ProvidersKey = 'providers'
const ChosenIndustryKey = 'currentChosen'
const AgentsKey = 'agents'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

// 渠道profileId
export function getSelectedChannel() {
  return Number(localStorage.getItem(SelectedChannelKey))
}

// 渠道id
export function setSelectedChannel(SelectedChannelId) {
  window.localStorage.setItem('userProfiles', SelectedChannelId)
  document.cookie = `x-sec-profile=${SelectedChannelId};path=/` // 下载之类的请求走的不是ajax，只能这样写
  localStorage.setItem(SelectedChannelKey, SelectedChannelId)
  return getSelectedChannel()
}

// 渠道代理商Id
export function getSelectedAgent() {
  return localStorage.getItem(SelectedAgentKey)
}

// 设置渠道代理商信息
export function setSelectedAgent(SelectedAgentId, SelectedAgentName) {
  localStorage.setItem(SelectedAgentKey, SelectedAgentId)
  if (SelectedAgentName) {
    document.cookie = `x-sec-subject-agent-id=${SelectedAgentId}; path=/`
    document.cookie = `x-sec-subject-agent-name=${encodeURI(
      SelectedAgentName,
    )}; path=/`
  }
  return getSelectedAgent()
}
// channels
export function getChannels() {
  return JSON.parse(localStorage.getItem(ChannelsKey))
}

export function setChannels(channels) {
  localStorage.setItem(ChannelsKey, JSON.stringify(channels))
  return getChannels()
}
// 内部账号
export function setInnerUser(val) {
  return localStorage.setItem(InnerUserKey, val)
}

export function getInnerUser() {
  // 返回应该是个boolean类型
  return ['true', true].includes(localStorage.getItem(InnerUserKey))
}
// platform
export function getPlatform() {
  return localStorage.getItem(PlatformKey)
}

export function setPlatform(platformKey) {
  localStorage.setItem(PlatformKey, platformKey)
  return getPlatform()
}
export function removePlatform() {
  localStorage.removeItem(PlatformKey)
}

// 获取用户ID
export function getUserId() {
  return localStorage.getItem(UserIDKey)
}

export function setSelectedProvider(profileId) {
  localStorage.setItem(SelectedProviderKey, profileId)
}

export function getSelectedProvider() {
  return localStorage.getItem(SelectedProviderKey)
}

export function setProviders(providers) {
  localStorage.setItem(ProvidersKey, JSON.stringify(providers))
}

export function getProviders() {
  return JSON.parse(localStorage.getItem(ProvidersKey) || '[]')
}

export function getChosen() {
  return JSON.parse(localStorage.getItem(ChosenIndustryKey) || '{}')
}

export function setChosen({ id, name, platformType }) {
  localStorage.setItem(
    ChosenIndustryKey,
    JSON.stringify({ id, name, platformType }),
  )
  return getChosen()
}

export function getAgents() {
  return JSON.parse(localStorage.getItem(AgentsKey) || '[]')
}

export function setAgents(agents) {
  localStorage.setItem(AgentsKey, JSON.stringify(agents))
  return getAgents()
}

export const subjectEnums = (subjectType = '') => ({
  id: `x-sec-subject-${subjectType}-id`,
  name: `x-sec-subject-${subjectType}-name`,
})

export const setOpenHeaders = (userContext = {}) => {
  const {
    subjectType,
    subjectId,
    subjectName,
    lvl1SubjectId,
    lvl1SubjectName,
  } = userContext
  const enums = subjectEnums(subjectType)
  const baseConfig = {
    [enums.id]: subjectId,
    [enums.name]: encodeURI(subjectName),
  }
  return {
    app: {
      ...baseConfig,
      'x-sec-lvl1subject-app-id': lvl1SubjectId,
      'x-sec-lvl1subject-app-name': encodeURI(lvl1SubjectName),
    },
    company: baseConfig,
  }
}
