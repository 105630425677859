var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"问题反馈","width":"800px","zIndex":2021},on:{"ok":_vm.submit,"cancel":_vm.closeModal}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{span: 4},"wrapper-col":{span: 18}}},[(!_vm.userInformation.name)?_c('a-form-item',{attrs:{"label":"登录账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['loginName', {
          rules: [{
            required: true,
            message: '请填写登录账号'
          }]
        }]),expression:"['loginName', {\n          rules: [{\n            required: true,\n            message: '请填写登录账号'\n          }]\n        }]"}],attrs:{"placeholder":"请填写用户名"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"问题描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description', {
          rules: [{
            required: true,
            message: '请填写问题描述'
          }]
        }]),expression:"['description', {\n          rules: [{\n            required: true,\n            message: '请填写问题描述'\n          }]\n        }]"}],attrs:{"autoSize":{ minRows: 2, maxRows: 6 },"placeholder":"请填写问题描述"}})],1),_c('a-form-item',{attrs:{"label":"问题截图"}},[_c('ayg-upload-oss',{directives:[{name:"decorator",rawName:"v-decorator",value:(['downloadCodes', {
          rules: [{ required: true,  message: '请上传问题截图' }]
        }]),expression:"['downloadCodes', {\n          rules: [{ required: true,  message: '请上传问题截图' }]\n        }]"}],attrs:{"buttonText":"可以同时上传多张","multiple":"","customFileName":_vm.customFileName,"imageList":_vm.imageList},on:{"upload-success":_vm.uploadSuccess}}),(!_vm.downloadCodes.length && _vm.submitFlag)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("请上传问题截图")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }