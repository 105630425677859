import Vue from 'vue'

const vm = new Vue()

export function login(loginName, password) {
  const data = {
    loginName,
    password,
  }
  return vm.$post('/api/sysmgr-web/auth/common-login', data)
}

// 是否内部用户
export function isInner() {
  return vm.$get('/api/agent-bff/user/inner-user-check')
}

// 代办事项的数量，落地端用到
export function getStatisticsInfo() {
  return vm.$get('/api/bffsc/workflow/statisticsInfo')
}

// 代办事项的数量，落地公司入驻的代办，从业务系统拿
export function getApplyInfo() {
  return vm.$get('/api/service-company-bff/settled/application/list?tab=2')
}

// 销售端-合规通tab获取红点
export function getEvaluatRredDot(params) {
  return vm.$get('/api/risk-bff/compliance/evaluate/redDot', params)
}

// 获取行业类型列表，（外部上传页面需要）
export function getIndustryList() {
  return vm.$get('/api/sysmgr-web/commom/option?enumType=IndustryType', {})
}

// P端 报税列表待办数量
export function queryTaxReportOrder(params) {
  return vm.$post('/api/bffsc/salemgt/reportOrder/queryTaxReportOrder', params)
}

// P端 付款单列表待办数量
export function queryPaymentOrderNum(params) {
  return vm.$post('/api/bffsc/salemgt/payOrder/pagelist', params)
}

// open端-合规通以及合规任务获取红点
export function getTaskRedDot(params) {
  return vm.$get('/api/risk-bff/compliance/evaluate/openRedot', params)
}

// 获取P端待办数量接口
export function getFlowNum(params) {
  return vm.$post('/api/taxplan-workflow/taskCommon/getFlowNum', params)
}
