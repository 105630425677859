// 获取浏览器信息
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  const browsers = ['chrome', 'trident', 'edge', 'windowswechat', 'macwechat', 'mobile']
  const sys = {}
  browsers.forEach((item) => {
    const re = /(chrome).*?([\d.]+)/
    const m = userAgent.match(re)
    if (userAgent.indexOf(item) > -1) {
      Object.assign(sys, {
        browser: item,
        ver: item === 'chrome' ? m[2] : 0,
      })
    }
  })
  console.log(sys)
  return sys
}

const getBrowserVersionInfo = () => {
  const ua = window.navigator.userAgent
  const {
    length,
  } = ua
  const segment = Math.ceil(length / 50)
  const options = {}
  for (let i = 0; i < segment; i++) { // eslint-disable-line
    options[`UA${i}`] = ua.substr(i * 50, 50)
  }
  return options
}
export {
  getBrowserInfo,
  getBrowserVersionInfo,
}
