import {
  Layout,
  Menu,
  Icon,
  Spin,
  message,
  Form,
  Input,
  Button,
  notification,
  Dropdown,
  Divider,
  Modal,
  Table,
  Select,
  Tabs,
  Row,
  Col,
  ConfigProvider,
  Checkbox,
  Radio,
  Badge,
  Upload,
  BackTop,
  Breadcrumb,
  Carousel,
  Result,
} from 'ant-design-vue'

const antd = {
  install(Vue) {
    Vue.use(Layout)
    Vue.use(Menu)
    Vue.use(Icon)
    Vue.use(Spin)
    Vue.use(Form)
    Vue.use(Input)
    Vue.use(Button)
    Vue.use(Dropdown)
    Vue.use(Divider)
    Vue.use(Modal)
    Vue.use(Table)
    Vue.use(Select)
    Vue.use(Tabs)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(ConfigProvider)
    Vue.use(Checkbox)
    Vue.use(Radio)
    Vue.use(Badge)
    Vue.use(Upload)
    Vue.use(BackTop)
    Vue.use(Breadcrumb)
    Vue.use(Carousel)
    Vue.use(Result)

    Vue.prototype.$message = message
    Vue.prototype.$notification = notification
    Vue.prototype.$info = Modal.info
    Vue.prototype.$success = Modal.success
    Vue.prototype.$error = Modal.error
    Vue.prototype.$warning = Modal.warning
    Vue.prototype.$confirm = Modal.confirm
    Vue.prototype.$destroyAll = Modal.destroyAll
  },
}

export default antd
