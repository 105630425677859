<template>
  <a-modal
    v-model="show"
    title="切换"
    :footer="null"
    :mask-closable="false"
    @cancel="
      () => {
        form.resetFields();
      }
    "
  >
    <a-tabs
      v-model="formType"
      @change="$emit('query', { subjectType: $event })"
    >
      <a-tab-pane tab="企业" key="company" />
      <a-tab-pane tab="商户" key="app" />
    </a-tabs>
    <a-form :form="form" layout="inline">
      <a-form-item :label="names[type].keyName" name="subjectName">
        <a-input
          v-decorator="[
            'subjectName',
            {
              required: true,
              message: '请输入关键字',
            },
          ]"
          placeholder="请输入关键字"
          size="small"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" size="small" @click="query">查询</a-button>
      </a-form-item>
      <a-form-item>
        <a-button size="small" @click="clear">清空</a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :data-source="industries"
      :pagination="{
        current: current,
        pageSize: pageSize,
        size: 'small',
        total: total,
        showTotal: (total) => `共${total}条`,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
      }"
      :loading="loading"
      :scroll="{ y: 300 }"
      rowKey="subjectId"
      @change="tableChange"
    >
      <span slot="action" slot-scope="text, record">
        <a
          href="javascript:;"
          class="ant-dropdown-link"
          @click="$emit('change', record)"
          >{{ names[type].actionName }}</a
        >
      </span>
    </a-table>
  </a-modal>
</template>
<script>
export default {
  props: {
    showModal: Boolean,
    companies: Array,
    industries: Array,
    type: String,
    page: Number,
    pageSize: Number,
    total: Number,
    loading: Boolean,
  },
  data() {
    return {
      columns: [
        {
          dataIndex: 'subjectName',
          title: '企业名称',
        },
        {
          dataIndex: 'subjectId',
          title: '操作',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      names: {
        company: {
          actionName: '切换公司',
          keyName: '企业关键字',
        },
        app: {
          actionName: '切换商户',
          keyName: '商户关键字',
        },
      },
    }
  },
  computed: {
    formType: {
      get() {
        return this.type
      },
      set(val) {
        this.$emit('update:type', val)
      },
    },
    current: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      },
    },
    show: {
      get() {
        return this.showModal
      },
      set(val) {
        if (!val) {
          this.form.resetFields()
        }
        this.$emit('update:showModal', val)
      },
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  methods: {
    tableChange(pagination) {
      this.$emit('query', {
        ...{ page: pagination.current, pageSize: pagination.pageSize },
      })
    },
    query() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit('query', { ...values, page: 1 })
        }
      })
    },
    clear() {
      this.form.resetFields()
      this.$emit('query', { subjectName: '' })
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-tabs {
  /deep/ .ant-tabs-bar {
    border-bottom: 0;
  }
}
.input-case {
  display: flex;
  align-items: center;
  .ant-input {
    width: 200px;
    margin-right: 10px;
  }
  .ant-btn {
    margin-right: 10px;
  }
}
</style>
