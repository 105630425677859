/**
 * 不同项目在登陆完后需要做的操作
 */
import tenantNav from '@/components/headerNav/tenantNav.vue'
import agentNav from '@/components/headerNav/agentNav.vue'
import openNav from '@/components/headerNav/openNav.vue'
import providerNav from '@/components/headerNav/providerNav.vue'
import scrmAgentNav from '@/components/headerNav/scrmAgentNav.vue'
import {
  setToken,
  setSelectedChannel,
  setSelectedAgent,
  setChannels,
  setProviders,
  setOpenHeaders,
} from './auth'
import Cookies from 'js-cookie'

const platformEnum = {
  // open,oem
  'console-company': {
    microName: 'micro-open',
    loginOk(state, payload) {
      const { platformType } = state
      const userProfiles = localStorage.getItem('userProfiles')
      const currentProfile = (payload.userProfiles || []).find(
        (el) => `${el.id}` === `${userProfiles}`,
      )
        || (payload.userProfiles || [])[0]
        || {}
      const { id, employeeId } = currentProfile || {}
      // 没get到userProfiles 或者get到的id跟userProfiles[0].id 不一样，重新设置userProfiles
      if (!userProfiles || userProfiles !== id) {
        localStorage.setItem('userProfiles', id)
        localStorage.removeItem('userContext')
      }
      window.sessionStorage.setItem(
        'companyList',
        JSON.stringify(payload.userProfiles || []),
      )
      Cookies.remove('x-sec-lvl1subject-app-id')
      Cookies.remove('x-sec-lvl1subject-app-name')
      Cookies.remove('x-sec-subject-company-id')
      Cookies.remove('x-sec-subject-company-name')
      Cookies.remove('x-sec-subject-app-id')
      Cookies.remove('x-sec-subject-app-name')
      Cookies.set('x-access-token', payload.access_token)
      Cookies.set('redash-profile', id, { domain: '.aiyuangong.com' })
      const userContext = JSON.parse(
        localStorage.getItem('userContext') || '{}',
      )
      const config = setOpenHeaders(userContext)[userContext.subjectType] || {}
      localStorage.setItem(
        'headerConfig',
        JSON.stringify({
          'x-sec-profile': id,
          'x-sec-employee': employeeId,
          platformType,
          ...config,
        }),
      )
    },
    logout() {
      const date = new Date().toGMTString()
      window.localStorage.removeItem('userProfiles')
      window.localStorage.removeItem('headerConfig')
      window.sessionStorage.clear()
      document.cookie = `x-sec-profile=; path=/; expires=${date}`
      document.cookie = `redash-profile=;domain=.aiyuangong.com;path=/; expires=${date}`
      document.cookie = `x-sec-subject-app-id=; path=/; expires=${date}`
      document.cookie = `x-sec-subject-app-name=; path=/; expires=${date}`
      document.cookie = `x-sec-lvl1subject-app-id=; path=/; expires=${date}`
      document.cookie = `x-sec-lvl1subject-app-name=; path=/; expires=${date}`
      document.cookie = `x-sec-subject-company-id=; path=/; expires=${date}`
      document.cookie = `x-sec-subject-company-name=; path=/; expires=${date}`
    },
    navComponent: openNav,
    logoName: '企业服务平台',
    loginBackground: require('@/assets/open.jpg'), // 登录页背景图
  },
  // 销售渠道
  'console-agent': {
    microName: 'micro-agent',
    loginOk(state, payload) {
      setToken(payload.access_token) // token
      setChannels(payload.userProfiles) // 当前账号下的渠道列表
    },
    logout() {
      window.localStorage.removeItem('userProfiles')
      window.sessionStorage.clear()
      document.cookie = 'x-sec-profile=; path=/'
      document.cookie = 'redash-profile=;domain=.aiyuangong.com;path=/'
      document.cookie = 'x-sec-subject-agent-id=; path=/'
      document.cookie = 'x-sec-subject-agent-name=; path=/'
      document.cookie = 'x-sec-platform-id=; path=/'
      document.cookie = 'x-access-token=; path=/'
    },
    navComponent: agentNav,
    logoName: '销售服务平台',
    logoImg: require('@/assets/logo.png'),
    host: 'sales',
    loginBackground: require('@/assets/sales.jpg'),
  },
  // 落地端
  'console-provider': {
    microName: 'micro-provider',
    loginOk(state, payload) {
      // token
      document.cookie = `x-access-token=${payload.access_token}`
      // companyId
      document.cookie = `x-sec-subject-company-id=${state.oem.companyId}`
      document.cookie = `x-sec-subject-company-name=${state.oem.companyName}`
      // platformId
      document.cookie = `x-sec-platform-id=${state.oem.platformId}`
      // profileId，权限改造需要弹窗选择操作对象
      setProviders(payload.userProfiles)
      window.localStorage.setItem('accessToken', payload.access_token)
      window.localStorage.setItem('providerLoginData', JSON.stringify(payload))
    },
    logout() {
      window.localStorage.removeItem('userProfiles')
      window.sessionStorage.clear()
      document.cookie = 'x-sec-profile=; path=/'
      document.cookie = 'redash-profile=;domain=.aiyuangong.com;path=/'
      document.cookie = 'x-sec-subject-company-id=; path=/'
      document.cookie = 'x-sec-subject-company-name=; path=/'
      document.cookie = 'x-sec-platform-id=; path=/'
      document.cookie = 'x-access-token=; path=/'
    },
    navComponent: providerNav,
    logoName: '服务商门户',
    logoImg: require('@/assets/logo.png'),
    host: 'p-',
    loginBackground: require('@/assets/bg.jpg'),
  },
  // console-admin 运营平台
  'console-admin': {
    microName: 'micro-admin',
    loginOk(state, payload) {
      window.localStorage.setItem('userProfiles', payload.userProfiles[0].id)
      Cookies.set('x-sec-profile', payload.userProfiles[0].id)
      Cookies.set('redash-profile', payload.userProfiles[0].id, {
        domain: '.aiyuangong.com',
      })
    },
    logoName: '爱员工开放平台',
    logoImg: require('@/assets/logo.png'),
    host: 'openadmin',
    loginBackground: require('@/assets/open.jpg'),
  },
  'console-tenant': {
    microName: 'micro-tenant',
    loginOk(state, payload) {
      state.selectedChannel = payload.userProfiles[0].id
      setToken(payload.access_token) // token
      setSelectedChannel(payload.userProfiles[0].id) // 当前渠道商id
      setSelectedAgent(payload.userProfiles[0].subjectId) // 当前代理商id
      setChannels(payload.userProfiles) // 当前账号下的渠道列表
    },
    navComponent: tenantNav,
    logoName: 'SaaS管理后台',
    logoImg: require('@/assets/logo.png'),
    host: 'openadmin',
    loginBackground: require('@/assets/open.jpg'),
    headerTheme: 'tenant-theme',
  },
  // 善赚营销服务平台 sales
  'console-scrm': {
    microName: 'micro-scrm',
    loginOk(state, payload) {
      setToken(payload.access_token) // token
      setChannels(payload.userProfiles) // 当前账号下的渠道列表
    },
    logout() {
      window.localStorage.removeItem('userProfiles')
      window.localStorage.removeItem('isScrmRegister')
      window.localStorage.removeItem('headerConfig')
      window.sessionStorage.clear()
      Cookies.remove('x-sec-profile')
      Cookies.remove('redash-profile', { domain: '.aiyuangong.com' })
      Cookies.remove('x-sec-subject-agent-id')
      Cookies.remove('x-sec-subject-agent-name')
      Cookies.remove('x-sec-platform-id')
      Cookies.remove('x-access-token')
    },
    navComponent: scrmAgentNav,
    logoName: '营销服务平台',
    logoImg: require('@/assets/scrm_logo.png'),
    host: 'scrm',
    loginBackground: require('@/assets/scrmBg.png'),
  },
}

export default platformEnum
